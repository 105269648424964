import { defineStore } from 'pinia'
import type {
  AllocationProductScope,
  AllocationProductTree,
  ProductGroup,
  ProductPrice,
  ProductSubgroup,
  ProductSubgroupFlat,
  ProductType,
  ProductTypeFlat,
} from '@/types/productType'
import type { SortBy } from '@/types/types'
import ProductService from '@/services/ProductService'
import ClientService from '@/services/ClientService'

export const useProductStore = defineStore({
  id: 'products',
  state: (): {
    products: Map<
      string,
      ProductType & { timestamp?: number, outdated?: boolean }
    >
    productSubgroups: Map<string, ProductSubgroup & { timestamp?: number }>
    productGroups: Map<string, ProductGroup & { timestamp?: number }>
    productPage: number
    productFilters?: Record<string, any>
    productSortBy?: Record<string, 'ASC' | 'DESC'>
    productGroupFilters?: Record<string, any>
    productGroupSortBy?: Record<string, 'ASC' | 'DESC'>
    allocationProducts?: AllocationProductTree
    allocationRequestProducts?: AllocationProductTree
    allocationProductScopes?: AllocationProductScope
    allocationParams?: string
  } => ({
    products: new Map<string, ProductType>(),
    productPage: 1,
    productFilters: {
      status: 'active',
    },
    productSortBy: {
      name: 'ASC',
    },

    productSubgroups: new Map<string, ProductSubgroup>(),
    productGroups: new Map<string, ProductGroup>(),
    productGroupFilters: undefined,
    productGroupSortBy: {
      name: 'ASC',
    },

    allocationProducts: undefined,
    allocationRequestProducts: undefined,
    allocationProductScopes: undefined,
    allocationParams: undefined,
  }),

  actions: {
    async fetchNextProductPage(clear?: boolean) {
      if (clear) this.productPage = 1

      return ProductService.getProducts({
        page: this.productPage++,
        filters: this.productFilters,
        order: this.productSortBy,
      })
        .then((res) => {
          if (clear) this.products.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.products.set(x.id, x)))
    },

    async setProductFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.productFilters)) {
        this.productFilters = { ...filters }

        await this.fetchNextProductPage(true)
      }
    },

    async setProductSorting(sortBy?: SortBy) {
      if (sortBy !== this.productSortBy) {
        this.productSortBy = sortBy
          ? { [sortBy.field]: sortBy.direction }
          : undefined

        await this.fetchNextProductPage(true)
      }
    },

    async fetchProductSubgroups() {
      return ProductService.getProductSubgroups().then(({ data }) =>
        data.forEach((x) => this.productSubgroups.set(x.id, x)),
      )
    },

    async fetchAllProducts() {
      return (
        await ProductService.getProducts({
          page: 1,
          pageSize: 999,
        })
      ).data
    },

    async fetchProductGroups(clear?: boolean) {
      return ProductService.getProductGroups({
        filters: this.productGroupFilters,
        order: this.productGroupSortBy,
      })
        .then((res) => {
          if (clear) this.productGroups.clear()
          return res
        })
        .then(({ data }) =>
          data.forEach((x) => this.productGroups.set(x.id, x)),
        )
    },

    async setProductGroupFilters(filters: Record<string, any> = {}) {
      if (
        JSON.stringify(filters) !== JSON.stringify(this.productGroupFilters)
      ) {
        this.productGroupFilters = { ...filters }

        await this.fetchProductGroups(true)
      }
    },

    async setProductGroupSorting(sortBy?: SortBy) {
      if (sortBy !== this.productGroupSortBy) {
        this.productGroupSortBy = sortBy
          ? { [sortBy.field]: sortBy.direction }
          : undefined

        await this.fetchProductGroups(true)
      }
    },

    async getAllProductGroups() {
      return (await ProductService.getProductGroups()).data
    },

    async addNewProduct(product: Omit<ProductTypeFlat, 'id'>) {
      const { data } = await ProductService.postProduct(product)

      this.products.set(data.id, data)

      return data
    },

    async addNewProductSubgroup(
      productSubgroup: Omit<ProductSubgroupFlat, 'id'>,
    ) {
      return ProductService.postProductSubgroup(productSubgroup)
    },

    async addNewProductGroup(productGroup: Omit<ProductGroup, 'id'>) {
      const { data } = await ProductService.postProductGroup(productGroup)

      this.productGroups.set(data.id, data)

      return data
    },

    async getProductSubgroups(args?: { productGroup?: string }) {
      return (await ProductService.getProductSubgroups(args)).data
    },

    async getProductTreeProducts() {
      return (await ProductService.getProductTreeProducts()).data
    },

    async getProductTreeSubgroups() {
      return (await ProductService.getProductTreeSubgroups()).data
    },

    async updateProduct(product: ProductTypeFlat) {
      const { data } = await ProductService.putProduct(product)

      this.products.set(product.id, data)

      return data
    },

    async updateProductSubgroup(productSubgroup: ProductSubgroupFlat) {
      const { data } = await ProductService.putProductSubgroup(productSubgroup)

      this.productSubgroups.set(productSubgroup.id, data)

      return data
    },

    async updateProductGroup(productGroup: ProductGroup) {
      const { data } = await ProductService.putProductGroup(productGroup)

      this.productGroups.set(productGroup.id, data)

      return data
    },

    async deleteProduct(productId: string) {
      await ProductService.deleteProduct(productId)

      this.products.delete(productId)
    },

    async deleteProductSubgroup(productSubgroupId: string) {
      await ProductService.deleteProductSubgroup(productSubgroupId)

      this.productSubgroups.delete(productSubgroupId)
      this.products = new Map<string, ProductType>()
      this.productPage = 1
    },

    async deleteProductGroup(productGroupId: string) {
      await ProductService.deleteProductGroup(productGroupId)

      this.productGroups.delete(productGroupId)
      this.productSubgroups = new Map<string, ProductSubgroup>()
    },

    async getAllocationProducts(
      clientId: string,
      startDate: string,
      isAllocationRequest: boolean,
    ) {
      if (
        this.allocationProducts &&
        this.allocationParams === clientId + startDate + isAllocationRequest
      ) {
        return Promise.resolve(this.allocationProducts)
      }

      const { data } = await ClientService.getAllocationProductsByClient(
        clientId,
        startDate,
        isAllocationRequest,
      )

      this.allocationProducts = data
      this.allocationParams = clientId + startDate + isAllocationRequest

      return data
    },

    async getAllocationProductScopes(
      clientId: string,
      productId: string,
      startDate: string,
      isAllocationRequest: boolean,
    ) {
      if (
        this.allocationProductScopes &&
        this.allocationParams ===
          clientId + productId + startDate + isAllocationRequest
      ) {
        return Promise.resolve(this.allocationProductScopes)
      }

      const { data } = await ClientService.getAllocationProductScopesByClient(
        clientId,
        productId,
        startDate,
        isAllocationRequest,
      )

      this.allocationProductScopes = data
      this.allocationParams =
        clientId + productId + startDate + isAllocationRequest

      return data
    },

    async addNewProductPrice(productId: string, price: ProductPrice) {
      const { data } = await ProductService.postProductPrice(productId, price)

      const current = this.products.get(productId)

      if (current)
        this.products.set(productId, {
          ...current,
          prices: current.prices ? [...current.prices, data] : [data],
        })

      return data
    },

    async updateProductPrice(productId: string, price: ProductPrice) {
      const { data } = await ProductService.putProductPrice(productId, price)

      const current = this.products.get(productId)

      if (current?.prices)
        this.products.set(productId, {
          ...current,
          prices: current.prices.map((x) => (x.id === data.id ? data : x)),
        })

      return data
    },

    async deleteProductPrice(productId: string, priceId: string) {
      await ProductService.deleteProductPrice(productId, priceId)

      const current = this.products.get(productId)

      if (current?.prices)
        this.products?.set(productId, {
          ...current,
          prices: current.prices.filter((x) => x.id !== priceId),
        })
    },

    async fetchProductById(id: string, cached = true) {
      const local = this.products.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await ProductService.getProductById(id)

        // API returns one price in the `prices` array when its since and until are before and after now.
        if (data.prices?.length) data.currentPrice = data.prices[0].price

        this.products.set(id, {
          ...(local?.outdated ? local : {}),
          ...data,
          timestamp: new Date().getTime(),
          outdated: false,
        })

        return data
      }

      return local
    },

    async markProductAsOutdated(id: string, watching: boolean) {
      const local = this.products.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchProductById(id, false)
      }
    },

    async fetchProductSubgroupById(id: string, cached = true) {
      const local = this.productSubgroups.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime())
      ) {
        const { data } = await ProductService.getProductSubgroupById(id)

        this.productSubgroups.set(id, {
          ...data,
          timestamp: new Date().getTime(),
        })

        return data
      }

      return local
    },

    async fetchProductGroupById(id: string, cached = true) {
      const local = this.productGroups.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime())
      ) {
        const { data } = await ProductService.getProductGroupById(id)

        this.productGroups.set(id, {
          ...data,
          timestamp: new Date().getTime(),
        })

        return data
      }

      return local
    },

    async fetchPricesByProductId(productId: string) {
      const { data } = await ProductService.getPricesByProductId(productId)

      const current = this.products.get(productId)

      if (current)
        this.products.set(productId, {
          ...current,
          prices: data,
        })

      return data
    },
  },
  getters: {
    productsAsArray: (state) =>
      Array.from<ProductType>(state.products.values()),

    productSubgroupsAsArray: (state) =>
      Array.from<ProductSubgroup>(state.productSubgroups.values()),

    productGroupsAsArray: (state) =>
      Array.from<ProductGroup>(state.productGroups.values()),

    findOrThrowProductById: (state) => {
      return (id: string) => {
        const product = state.products.get(id)

        if (!product) throw new Error()

        return product
      }
    },

    findOrThrowProductSubgroupById: (state) => {
      return (id: string) => {
        const productSubgroup = state.productSubgroups.get(id)

        if (!productSubgroup) throw new Error()

        return productSubgroup
      }
    },

    findOrThrowProductGroupById: (state) => {
      return (id: string) => {
        const productGroup = state.productGroups.get(id)

        if (!productGroup) throw new Error()

        return productGroup
      }
    },

    findOrThrowPricesByProductId: (state) => {
      return (id: string) => {
        const prices = state.products.get(id)?.prices

        if (!prices) throw new Error()

        return prices
      }
    },

    findOrThrowProductPriceById: (state) => {
      return (productId: string, priceId: string) => {
        const price = state.products
          ?.get(productId)
          ?.prices?.find((x) => x.id === priceId)

        if (!price) throw new Error('Not found')

        return price
      }
    },
  },
})
