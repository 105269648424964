import axios from 'axios'
import type {
  ClientAddress,
  ClientContact,
  ClientDashboardData,
  ClientType,
} from '@/types/clientType'
import type {
  AllocationProductScope,
  AllocationProductTree,
} from '@/types/productType'
import type { AllocationSupplier } from '@/types/supplierType'

export default class {
  static async getClients({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<ClientType[]>('clients', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async postClient(client: Omit<ClientType, 'id'>) {
    return axios.post<ClientType>(`clients`, client)
  }

  static async getClientById(id: string) {
    return axios.get<ClientType>(`clients/${id}`)
  }

  static async putClient(client: ClientType) {
    return axios.put<ClientType>(`clients/${client.id}`, client)
  }

  static async getClientAddressById(clientId: string, addressId: string) {
    return axios.get<ClientAddress>(
      `clients/${clientId}/client_addresses/${addressId}`,
    )
  }

  static async postClientAddress(
    clientId: string,
    address: Omit<ClientAddress, 'id'>,
  ) {
    return axios.post<ClientAddress>(
      `clients/${clientId}/client_addresses`,
      address,
    )
  }

  static async putClientAddress(clientId: string, address: ClientAddress) {
    return axios.put<ClientAddress>(
      `clients/${clientId}/client_addresses/${address.id}`,
      address,
    )
  }

  static async deleteClientAddress(clientId: string, addressId: string) {
    return axios.delete(`clients/${clientId}/client_addresses/${addressId}`)
  }

  static async postClientContact(
    clientId: string,
    contact: Omit<ClientContact, 'id'>,
  ) {
    return axios.post<ClientContact>(
      `clients/${clientId}/client_contacts`,
      contact,
    )
  }

  static async putClientContact(clientId: string, contact: ClientContact) {
    return axios.put<ClientContact>(
      `clients/${clientId}/client_contacts/${contact.id}`,
      contact,
    )
  }

  static async deleteClientContact(clientId: string, contactId: string) {
    return axios.delete(`clients/${clientId}/client_contacts/${contactId}`)
  }

  static async getAllocationProductsByClient(
    clientId: string,
    startDate: string,
    isAllocationRequest: boolean,
  ) {
    return axios.get<AllocationProductTree>(
      isAllocationRequest
        ? `clients/${clientId}/allocation_requests/products/${startDate}`
        : `clients/${clientId}/allocations/products/${startDate}`,
    )
  }

  static async getAllocationSuppliersByProduct(
    clientId: string,
    productId: string,
    startDate: string,
    isAllocationRequest: boolean,
  ) {
    return axios.get<AllocationSupplier[]>(
      isAllocationRequest
        ? `clients/${clientId}/allocation_requests/product/${productId}/suppliers/${startDate}`
        : `clients/${clientId}/allocations/product/${productId}/suppliers/${startDate}`,
    )
  }

  static async getAllocationProductScopesByClient(
    clientId: string,
    productId: string,
    startDate: string,
    isAllocationRequest: boolean,
  ) {
    return axios.get<AllocationProductScope>(
      isAllocationRequest
        ? `clients/${clientId}/allocation_requests/product/${productId}/volume/${startDate}`
        : `clients/${clientId}/allocations/product/${productId}/volume/${startDate}`,
    )
  }

  static async getClientDashboardDataById(id: string) {
    return axios.get<ClientDashboardData>(`clients/${id}/dashboard`)
  }
}
