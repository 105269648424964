import axios from 'axios'
import type { FulfillmentType } from '@/types/fulfillmentType'
import type { EntityTypeOf } from '@/types/types'

export default class {
  static async getFulfillmentsByEntityAndId(
    entityType: EntityTypeOf,
    entityId: string,
  ) {
    return axios.get<FulfillmentType[]>('fulfillments', {
      params: { [entityType]: entityId },
    })
  }

  static async getFulfillmentById(id: string) {
    return axios.get<FulfillmentType>(`fulfillments/${id}`)
  }

  static async putFulfillment(fulfillment: FulfillmentType) {
    return axios.put<FulfillmentType>(
      `fulfillments/${fulfillment.id}`,
      fulfillment,
    )
  }
}
