<template>
  <div class="task-list">
    <Section
      :text="t('tasks.list.title')"
      :offset="12"
    >
      <div class="task-list__items">
        <template
          v-for="task in dataAsArray"
          :key="task.id"
        >
          <ListTaskItem
            v-if="taskPermissions(task.type)"
            :task="task"
            @click="checkRead(task)"
          />
        </template>
      </div>
    </Section>
    <WaimoLoader
      v-if="taskListLoaderActive"
      class="task-list__lazy-loader-indicator"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { WaimoLoader } from '@finetic/waimo-ui'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import Section from '@/components/ui/list/ListSection.vue'
import ListTaskItem from '@/components/ui/list/ListTaskItem.vue'
import { useTaskStore } from '@/stores/tasks'
import { TaskTypeOf, type TaskType } from '@/types/taskType'
import { RolePermission } from '@/types/roleType'
import { isAuthorized } from '@/utils/isAuthorized'

const { t } = useI18n()

const taskStore = useTaskStore()
taskStore.setSorting({
  field: 'createdAt',
  direction: 'DESC',
})
const { dataAsArray } = storeToRefs(taskStore)

const taskListLoaderActive = ref(false)

const loadNextPage = async () => {
  taskListLoaderActive.value = true
  await taskStore
    .fetchNextPage()
    .finally(() => (taskListLoaderActive.value = false))
}

if (!dataAsArray.value.length) loadNextPage()

const taskPermissions = (taskType: TaskTypeOf): boolean => {
  switch (taskType) {
    // Allocations
    case TaskTypeOf.START_DATE_RECEIVED:
    case TaskTypeOf.END_DATE_RECEIVED:
      return isAuthorized(RolePermission.ALLOCATION_WRITE)

    // Allocation requests
    case TaskTypeOf.ALLOCATION_REQUEST_RECEIVED:
      return isAuthorized(RolePermission.ALLOCATION_REQUEST_WRITE)

    // User
    case TaskTypeOf.NEW_USER:
      return isAuthorized(RolePermission.USER_WRITE)

    // Fulfillments
    case TaskTypeOf.ASSESS_FULFILLMENT_DEADLINE:
    case TaskTypeOf.ASSESS_FULFILLMENT_EXCEEDED:
    case TaskTypeOf.ASSESS_FULFILLMENT_BUDGET:
    case TaskTypeOf.FULFILLMENT_REJECTED:
    case TaskTypeOf.FULFILLMENT_APPROVED:
    case TaskTypeOf.FULFILLMENT_IN_PROGRESS:
    case TaskTypeOf.SUPPLIER_FULFILLMENT_CREDIT_EXPECTED:
      return isAuthorized(RolePermission.CLAIM_WRITE)

    // Complaints
    case TaskTypeOf.COMPLAINT_RECEIVED_BY_COMPLAINTS_CENTER:
    case TaskTypeOf.COMPLAINT_FORWARDED_TO_SUPPLIER:
    case TaskTypeOf.COMPLAINT_RESOLVED_BY_SUPPLIER:
    case TaskTypeOf.COMPLAINT_RESOLVED_NOT_SATISFIED:
      return isAuthorized(RolePermission.COMPLAINT_WRITE)
  }

  return false
}

const checkRead = (task: TaskType) => {
  if (!task.read) {
    task.read = true
    taskStore.updateTask(task)
  }
}

defineExpose({
  loadNextPage,
})
</script>

<style scoped lang="scss">
.task-list {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  &__lazy-loader-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4.8rem;
    background-color: var(--color-primary-20);
    border-radius: 0.8rem;
  }
}
</style>
