import { defineStore } from 'pinia'
import type {
  AllocationSupplier,
  SupplierContact,
  SupplierLocation,
  SupplierSpecialization,
  SupplierType,
} from '@/types/supplierType'
import type { SortBy } from '@/types/types'
import SupplierService from '@/services/SupplierService'
import ClientService from '@/services/ClientService'

export const useSupplierStore = defineStore({
  id: 'suppliers',
  state: (): {
    data: Map<string, SupplierType & { timestamp?: number, outdated?: boolean }>
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>

    allocationSuppliers: Map<string, AllocationSupplier>
    allocationParams?: string
  } => ({
    data: new Map(),
    page: 1,
    filters: undefined,
    sortBy: {
      name: 'ASC',
    },

    allocationSuppliers: new Map(),
    allocationParams: undefined,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return SupplierService.getSuppliers({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.data.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.data.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: SortBy) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async getAllSuppliers() {
      return (await SupplierService.getSuppliers({ page: 1, pageSize: 999 }))
        .data
    },

    async addNewSupplier(supplier: Omit<SupplierType, 'id'>) {
      return (await SupplierService.postSupplier(supplier)).data
    },

    async updateSupplier(supplier: SupplierType) {
      const { data } = await SupplierService.putSupplier(supplier)

      this.data.set(supplier.id, data)

      return data
    },

    async removeSupplier(supplierId: string) {
      await SupplierService.deleteSupplier(supplierId)

      this.data.delete(supplierId)
    },

    async addNewSupplierContact(
      supplierId: string,
      contact: Omit<SupplierContact, 'id'>,
    ) {
      const { data } = await SupplierService.postSupplierContact(
        supplierId,
        contact,
      )

      const current = this.data.get(supplierId)

      if (current)
        this.data.set(supplierId, {
          ...current,
          contacts: [...(current.contacts ?? []), data],
        })

      return data
    },

    async updateSupplierContact(supplierId: string, contact: SupplierContact) {
      const { data } = await SupplierService.putSupplierContact(
        supplierId,
        contact,
      )

      const current = this.data.get(supplierId)

      if (current)
        this.data.set(supplierId, {
          ...current,
          contacts: current.contacts.map((c) => (c.id === data.id ? data : c)),
        })

      return data
    },

    async removeSupplierContact(supplierId: string, supplierContactId: string) {
      await SupplierService.deleteSupplierContact(supplierId, supplierContactId)

      const current = this.data.get(supplierId)

      if (current)
        this.data.set(supplierId, {
          ...current,
          contacts: current.contacts.filter((c) => c.id !== supplierContactId),
        })
    },

    async addNewSupplierLocation(
      supplierId: string,
      location: Omit<SupplierLocation, 'id'>,
    ) {
      const { data } = await SupplierService.postSupplierLocation(
        supplierId,
        location,
      )

      const current = this.data.get(supplierId)

      if (current)
        this.data.set(supplierId, {
          ...current,
          locations: [...(current.locations ?? []), data],
        })

      return data
    },

    async updateSupplierLocation(
      supplierId: string,
      location: SupplierLocation,
    ) {
      const { data } = await SupplierService.putSupplierLocation(
        supplierId,
        location,
      )

      const current = this.data.get(supplierId)

      if (current)
        this.data.set(supplierId, {
          ...current,
          locations: current.locations.map((l) =>
            l.id === data.id ? data : l,
          ),
        })

      return data
    },

    async removeSupplierLocation(
      supplierId: string,
      supplierLocationId: string,
    ) {
      await SupplierService.deleteSupplierLocation(
        supplierId,
        supplierLocationId,
      )

      const current = this.data.get(supplierId)

      if (current)
        this.data.set(supplierId, {
          ...current,
          locations: current.locations.filter(
            (c) => c.id !== supplierLocationId,
          ),
        })
    },

    async removeSupplierSpecialization(
      supplierId: string,
      supplierSpecializationId: string,
    ) {
      await SupplierService.deleteSupplierSpecialization(
        supplierId,
        supplierSpecializationId,
      )

      const current = this.data.get(supplierId)

      if (current)
        this.data.set(supplierId, {
          ...current,
          specializations: current.specializations.filter(
            (c) => c.id !== supplierSpecializationId,
          ),
        })
    },

    async addNewSupplierSpecialization(
      supplierId: string,
      specialization: Omit<SupplierSpecialization, 'id'>,
    ) {
      const { data } = await SupplierService.postSupplierSpecialization(
        supplierId,
        specialization,
      )

      const current = this.data.get(supplierId)

      if (current)
        this.data.set(supplierId, {
          ...current,
          specializations: [...(current.specializations ?? []), data],
        })

      return data
    },

    async updateSupplierSpecialization(
      supplierId: string,
      specialization: SupplierSpecialization,
    ) {
      const { data } = await SupplierService.putSupplierSpecialization(
        supplierId,
        specialization,
      )

      const current = this.data.get(supplierId)

      if (current)
        this.data.set(supplierId, {
          ...current,
          specializations: current.specializations.map((e) =>
            e.id === data.id ? data : e,
          ),
        })

      return data
    },

    async getAllocationSuppliers(
      clientId: string,
      startDate: string,
      productId: string,
      isAllocationRequest: boolean,
    ) {
      if (
        this.allocationSuppliers &&
        this.allocationParams ===
          clientId + startDate + productId + isAllocationRequest
      ) {
        return Promise.resolve(this.allocationSuppliersAsArray)
      }

      const { data } = await ClientService.getAllocationSuppliersByProduct(
        clientId,
        productId,
        startDate,
        isAllocationRequest,
      )

      data.forEach((x) => this.allocationSuppliers.set(x.id, x))
      this.allocationParams =
        clientId + startDate + productId + isAllocationRequest

      return data
    },

    async fetchSupplierById(id: string, cached = true) {
      const local = this.data.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await SupplierService.getSupplierById(id)

        this.data.set(id, {
          ...(local?.outdated ? local : {}),
          ...data,
          timestamp: new Date().getTime(),
          outdated: false,
        })

        return data
      }

      return local
    },

    async markSupplierAsOutdated(id: string, watching: boolean) {
      const local = this.data.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchSupplierById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<SupplierType>(state.data.values()),

    allocationSuppliersAsArray: (state) =>
      Array.from<AllocationSupplier>(state.allocationSuppliers.values()),

    findOrThrowSupplierById: (state) => {
      return (id: string) => {
        const supplier = state.data.get(id)

        if (!supplier) throw new Error()

        return supplier
      }
    },

    findOrThrowSupplierContactById: (state) => {
      return (supplierId: string, contactId: string) => {
        const contact = state.data
          .get(supplierId)
          ?.contacts.find((x) => x.id === contactId)

        if (!contact) throw new Error()

        return contact
      }
    },

    findOrThrowSupplierLocationById: (state) => {
      return (supplierId: string, locationId: string) => {
        const location = state.data
          .get(supplierId)
          ?.locations.find((x) => x.id === locationId)

        if (!location) throw new Error()

        return location
      }
    },

    findOrThrowSupplierSpecializationById: (state) => {
      return (supplierId: string, specializationId: string) => {
        const specialization = state.data
          .get(supplierId)
          ?.specializations.find((x) => x.id === specializationId)

        if (!specialization) throw new Error()

        return specialization
      }
    },
  },
})
