import { defineStore } from 'pinia'
import ClientService from '@/services/ClientService'
import type {
  ClientContact,
  ClientAddress,
  ClientType,
} from '@/types/clientType'

export const useClientStore = defineStore({
  id: 'clients',
  state: (): {
    data: Map<string, ClientType & { timestamp?: number, outdated?: boolean }>
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>
  } => ({
    data: new Map<string, ClientType>(),
    page: 1,
    filters: undefined,
    sortBy: undefined,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return ClientService.getClients({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.data.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.data.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: { field: string, direction: 'ASC' | 'DESC' }) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async addNewClient(client: Omit<ClientType, 'id'>) {
      return (await ClientService.postClient(client)).data
    },

    async updateClient(client: ClientType) {
      const { data } = await ClientService.putClient(client)

      this.data.set(client.id, data)

      return data
    },

    async addNewClientAddress(
      clientId: string,
      address: Omit<ClientAddress, 'id'>,
    ) {
      const { data } = await ClientService.postClientAddress(clientId, address)

      const current = this.data.get(clientId)

      if (current)
        this.data?.set(clientId, {
          ...current,
          addresses: [...current.addresses, data],
        })

      return data
    },

    async updateClientAddress(clientId: string, address: ClientAddress) {
      const { data } = await ClientService.putClientAddress(clientId, address)

      const current = this.data.get(clientId)

      if (current)
        this.data.set(clientId, {
          ...current,
          addresses: current.addresses.map((a) =>
            a.id === data.id ? data : a,
          ),
        })

      return data
    },

    async deleteClientAddress(clientId: string, addressId: string) {
      await ClientService.deleteClientAddress(clientId, addressId)

      const current = this.data.get(clientId)

      if (current)
        this.data?.set(clientId, {
          ...current,
          addresses: current.addresses.filter((a) => a.id !== addressId),
        })
    },

    async addNewClientContact(
      clientId: string,
      contact: Omit<ClientContact, 'id'>,
    ) {
      const { data } = await ClientService.postClientContact(clientId, contact)

      const current = this.data.get(clientId)

      if (current)
        this.data?.set(clientId, {
          ...current,
          contacts: [...current.contacts, data],
        })

      return data
    },

    async updateClientContact(clientId: string, contact: ClientContact) {
      const { data } = await ClientService.putClientContact(clientId, contact)

      const current = this.data.get(clientId)

      if (current)
        this.data.set(clientId, {
          ...current,
          contacts: current.contacts.map((a) => (a.id === data.id ? data : a)),
        })

      return data
    },

    async deleteClientContact(clientId: string, contactId: string) {
      await ClientService.deleteClientContact(clientId, contactId)

      const current = this.data.get(clientId)

      if (current)
        this.data?.set(clientId, {
          ...current,
          contacts: current.contacts.filter((a) => a.id !== contactId),
        })
    },

    async fetchClientById(id: string, cached = true) {
      const local = this.data.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await ClientService.getClientById(id)

        this.data.set(id, {
          ...(local?.outdated ? local : {}),
          ...data,
          timestamp: new Date().getTime(),
          outdated: false,
        })

        return data
      }

      return local
    },

    async fetchClientDashboardDataById(id: string) {
      const { data } = await ClientService.getClientDashboardDataById(id)

      const current = this.data.get(id)

      if (current)
        this.data.set(id, {
          ...current,
          dashboardData: data,
        })

      return data
    },

    async markClientAsOutdated(id: string, watching: boolean) {
      const local = this.data.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchClientById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<ClientType>(state.data.values()),

    findOrThrowClientById: (state) => {
      return (clientId: string) => {
        const client = state.data?.get(clientId)

        if (!client) throw new Error('Not found')

        return client
      }
    },

    findOrThrowClientAddressById: (state) => {
      return (clientId: string, addressId: string) => {
        const address = state.data
          ?.get(clientId)
          ?.addresses.find((a) => a.id === addressId)

        if (!address) throw new Error('Not found')

        return address
      }
    },

    findOrThrowClientContactById: (state) => {
      return (clientId: string, contactId: string) => {
        const contact = state.data
          ?.get(clientId)
          ?.contacts.find((a) => a.id === contactId)

        if (!contact) throw new Error('Not found')

        return contact
      }
    },

    findOrThrowDashboardByClientId: (state) => {
      return (id: string) => {
        const dashboardData = state.data.get(id)?.dashboardData

        if (!dashboardData) throw new Error()

        return dashboardData
      }
    },
  },
})
