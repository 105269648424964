import type { ProductType } from './productType'

export enum RolePermission {
  ADMIN = 'admin',
  ALLOCATION_READ = 'allocation:read',
  ALLOCATION_WRITE = 'allocation:write',
  ALLOCATION_REQUEST_READ = 'allocation_request:read',
  ALLOCATION_REQUEST_WRITE = 'allocation_request:write',
  CLIENT_READ = 'client:read',
  CLIENT_WRITE = 'client:write',
  CONTRACT_READ = 'contract:read',
  CONTRACT_WRITE = 'contract:write',
  COMPLAINT_READ = 'complaint:read',
  COMPLAINT_WRITE = 'complaint:write',
  CREDITOR_READ = 'creditor:read',
  CREDITOR_WRITE = 'creditor:write',
  DEBTOR_READ = 'debtor:read',
  DEBTOR_WRITE = 'debtor:write',
  CLAIM_READ = 'claim:read',
  CLAIM_WRITE = 'claim:write',
  I_BERICHT_READ = 'i_bericht:read',
  I_BERICHT_WRITE = 'i_bericht:write',
  MUNICIPALITY_READ = 'municipality:read',
  MUNICIPALITY_WRITE = 'municipality:write',
  SUPPLIER_READ = 'supplier:read',
  SUPPLIER_WRITE = 'supplier:write',
  PAYMENT_READ = 'payment:read',
  PAYMENT_WRITE = 'payment:write',
  PRODUCT_READ = 'product:read',
  PRODUCT_WRITE = 'product:write',
  PRODUCT_GROUP_READ = 'product_group:read',
  PRODUCT_GROUP_WRITE = 'product_group:write',
  QUOTATION_READ = 'quotation:read',
  QUOTATION_WRITE = 'quotation:write',
  ROLE_MANAGEMENT_READ = 'role_management:read',
  ROLE_MANAGEMENT_WRITE = 'role_management:write',
  USER_READ = 'user:read',
  USER_WRITE = 'user:write',
}

export enum TaskPermission {
  ALLOCATION_NEARING_EXPIRATION_NOTIFICATION = 'allocation_nearing_expiration:notification',
  ALLOCATION_NEARING_EXPIRATION_READ = 'allocation_nearing_expiration:read',
  ALLOCATION_RECEIVED_NOTIFICATION = 'allocation_received:notification',
  ALLOCATION_RECEIVED_READ = 'allocation_received:read',
  ALLOCATION_REQUEST_RECEIVED_NOTIFICATION = 'allocation_request_received:notification',
  ALLOCATION_REQUEST_RECEIVED_READ = 'allocation_request_received:read',
  ASSESS_ALLOCATION_REQUEST_NOTIFICATION = 'assess_allocation_request:notification',
  ASSESS_ALLOCATION_REQUEST_READ = 'assess_allocation_request:read',
  ASSESS_FULFILLMENT_BUDGET_NOTIFICATION = 'assess_fulfillment_budget:notification',
  ASSESS_FULFILLMENT_BUDGET_READ = 'assess_fulfillment_budget:read',
  ASSESS_FULFILLMENT_DEADLINE_NOTIFICATION = 'assess_fulfillment_deadline:notification',
  ASSESS_FULFILLMENT_DEADLINE_READ = 'assess_fulfillment_deadline:read',
  ASSESS_FULFILLMENT_EXCEEDED_NOTIFICATION = 'assess_fulfillment_exceeded:notification',
  ASSESS_FULFILLMENT_EXCEEDED_READ = 'assess_fulfillment_exceeded:read',
  COMPLAINT_FORWARDED_TO_SUPPLIER_NOTIFICATION = 'complaint_forwarded_to_supplier:notification',
  COMPLAINT_FORWARDED_TO_SUPPLIER_READ = 'complaint_forwarded_to_supplier:read',
  COMPLAINT_RECEIVED_BY_COMPLAINTS_CENTER_NOTIFICATION = 'complaint_received_by_complaints_center:notification',
  COMPLAINT_RECEIVED_BY_COMPLAINTS_CENTER_READ = 'complaint_received_by_complaints_center:read',
  COMPLAINT_RESOLVED_BY_SUPPLIER_NOTIFICATION = 'complaint_resolved_by_supplier:notification',
  COMPLAINT_RESOLVED_BY_SUPPLIER_READ = 'complaint_resolved_by_supplier:read',
  COMPLAINT_RESOLVED_NOT_SATISFIED_NOTIFICATION = 'complaint_resolved_not_satisfied:notification',
  COMPLAINT_RESOLVED_NOT_SATISFIED_READ = 'complaint_resolved_not_satisfied:read',
  END_DATE_RECEIVED_NOTIFICATION = 'end_date_received:notification',
  END_DATE_RECEIVED_READ = 'end_date_received:read',
  FULFILLMENT_APPROVED_NOTIFICATION = 'fulfillment_approved:notification',
  FULFILLMENT_APPROVED_READ = 'fulfillment_approved:read',
  FULFILLMENT_CREDIT_EXPECTED_NOTIFICATION = 'fulfillment_credit_expected:notification',
  FULFILLMENT_CREDIT_EXPECTED_READ = 'fulfillment_credit_expected:read',
  FULFILLMENT_IN_PROGRESS_NOTIFICATION = 'fulfillment_in_progress:notification',
  FULFILLMENT_IN_PROGRESS_READ = 'fulfillment_in_progress:read',
  FULFILLMENT_REJECTED_NOTIFICATION = 'fulfillment_rejected:notification',
  FULFILLMENT_REJECTED_READ = 'fulfillment_rejected:read',
  NEW_USER_NOTIFICATION = 'new_user:notification',
  NEW_USER_READ = 'new_user:read',
  START_DATE_RECEIVED_NOTIFICATION = 'start_date_received:notification',
  START_DATE_RECEIVED_READ = 'start_date_received:read',
  SUPPLIER_FULFILLMENT_CREDIT_EXPECTED_NOTIFICATION = 'supplier_fulfillment_credit_expected:notification',
  SUPPLIER_FULFILLMENT_CREDIT_EXPECTED_READ = 'supplier_fulfillment_credit_expected:read',
}

export type RoleType = {
  id: string
  name: string
  permissions: Array<RolePermission | TaskPermission>
  products?: Array<Pick<ProductType, 'id' | 'name'>>
}

export type RoleTypeFlat = Omit<RoleType, 'products'> & {
  products: Array<ProductType['id']>
}
